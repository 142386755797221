<template>
  <div class="user-profile-page container mb-6">
    <div class="boxed elevation-1 mt-6 p-2">
      <div class="is-hanging-top">
        <div>
          <b-skeleton v-if="$apollo.loading" circle width="128" height="128" />
          <Avatar v-else size="huge" :seed-string="user | user_name" :color="user.avatar_color" />
        </div>
      </div>
      <div class="is-flex is-flex-direction-column is-align-items-center mt-6">
        <h1>
          <b-skeleton v-if="$apollo.loading" width="256" height="48" />
          <span v-else class="is-size-1">{{ user | user_name }}</span>
        </h1>
        <h2>
          <div class="is-flex is-align-items-center">
            <div class="mr-1">
              <b-skeleton v-if="$apollo.loading" width="128" height="20" />
              <span v-else class="is-size-4 has-text-weight-light">Joined</span>
            </div>
            <div>
              <b-skeleton v-if="$apollo.loading" width="256" height="20" />
              <span v-else class="is-size-4">{{ user.created_at | format("LLLL d, yyyy") }}</span>
            </div>
          </div>
        </h2>
      </div>
      <div v-if="stats.length > 0" class="is-flex mx-2 my-6 is-justify-content-space-around is-align-items-flex-start is-flex-wrap-wrap user-stats">
        <UserStat v-for="stat in stats" :key="stat.name" :label="stat.name" :number="stat.number" />
      </div>
      <div class="user-description">
        <h1 class="mb-2">
          <b-skeleton v-if="$apollo.loading" width="256" height="48" />
          <div v-else>
            <span class="is-size-2">About Me</span>
            <UserPill :user="user" class="ml-2" />
          </div>
        </h1>
        <div v-if="$apollo.loading">
          <b-skeleton width="375" height="20" />
          <b-skeleton width="300" height="20" />
          <b-skeleton width="225" height="20" />
          <b-skeleton width="150" height="20" />
        </div>
        <div v-else-if="!user.description">
          <p class="is-size-4 is-italic has-text-grey500 mb-1">
            <span v-if="user.id === $auth.user.id">
              You haven't entered any info about yourself. You can edit your about me from the
              <router-link to="/dashboard/profile">dashboard.</router-link>
            </span>
            <span v-else>
              This user has not entered anything about themself.
            </span>
          </p>
        </div>
        <div v-else>
          <p class="is-size-6 mb-1">
            {{ user.description }}
          </p>
        </div>
      </div>
      <div v-if="user" class="mt-2">
        <!-- TODO: List articles from author -->
        <!-- <AuthorCard :username="user.username" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/common/Avatar.vue";
import { GET_USER_BY_USERNAME } from "@/models/users/operations.gql";
// import AuthorCard from "@/components/resources/AuthorCard";
import UserPill from "@/components/common/UserPill.vue";
import UserStat from "@/components/users/UserStat.vue";

export default {
  name: "UserProfile",
  components: {
    Avatar,
    UserPill,
    UserStat
    // AuthorCard
  },
  data() {
    return {
      user: null,
      // placeholder
      stats: [
        // { name: "Events Attended", number: 10 },
        // { name: "Words Written", number: 5000 },
        // { name: "Submissions", number: 50 },
        // { name: "Completed Challenges", number: 20 }
      ]
    };
  },
  // apollo get public user info and show it here
  apollo: {
    user: {
      query: GET_USER_BY_USERNAME,
      variables() {
        return {
          username: this.$route.params.username
        };
      },
      update(data) {
        if (!data.users || data.users.length < 1) {
          // TODO: route to 404 page
          return {
            username: "404"
          };
        }
        return data.users[0];
      }
    }
  }
};
</script>

<style lang="scss">
.user-profile-page {
  .is-hanging-top {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }

  .user-description {
    margin: 3rem;
    @include mobile {
      margin: 3rem 0.5rem;
    }
  }

  .user-stats {
    row-gap: 1rem;
  }

  .user-stats > div {
    width: 25%;
    @include mobile {
      width: 50%;
    }
  }

  #user-description {
    width: 100%;
    padding: 0.25em;

    resize: none;
    font-size: 1.25em;
    color: inherit;
  }
}
</style>
