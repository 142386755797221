<template>
  <div class="is-flex is-flex-direction-column is-justify-content-center">
    <div class="has-text-centered is-size-1-tablet is-size-3-mobile">
      {{ number }}
    </div>
    <div class="has-text-centered is-size-4-tablet is-size-6-mobile">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "UserStat",
  props: {
    label: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    }
  }
};
</script>

